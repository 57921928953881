import {Attendee, AuthActionType, Tokens} from "../actions/auth";

interface State {
    isInitialising: boolean
    isAuthenticating: boolean
    isAuthenticated: boolean
    attendee: Attendee | null
    tokens: Tokens
}

const InitialState: State = {
    isInitialising: false,
    isAuthenticating: false,
    isAuthenticated: false,
    attendee: null,
    tokens: {accessToken: null, expiry: null},
};

export const authReducer = (state = InitialState, action: any) => {
    switch (action.type) {
        case AuthActionType.SET_INITIALISED:
            return {...state, isInitialising: false}
        case AuthActionType.SET_IS_AUTHENTICATING:
            return {...state, isAuthenticating: action.payload}
        case AuthActionType.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: true,
                attendee: {...action.payload.attendee},
                tokens: {...action.payload.tokens},
            }
        case AuthActionType.SET_UNAUTHENTICATED:
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: false,
                attendee: null,
                tokens: {...InitialState.tokens},
            }
        default:
            return state
    }
};

export default authReducer
