import {AxiosError} from 'axios';
import { EnqueueSnackbar } from 'notistack';

const handleError = (e: AxiosError|Error|string, enqueueSnackbar: EnqueueSnackbar) => {
    let message
    if (typeof e === "string") {
        message = e;
    } else if (e instanceof AxiosError && e.response?.data) {
        const { data }: { data: any } = e.response
        message = data.message
    } else {
        message = e.toString()
    }

    enqueueSnackbar(message, { variant: "error" })
}

export default handleError
