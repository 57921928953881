import {useSnackbar} from 'notistack';
import React, {ChangeEvent, useState} from 'react'
import {InputAdornment} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import OtpInput from 'react-otp-input'
import './style.scss'
import handleError from '../../../../api/handleError';
import {useDispatch, useSelector} from "react-redux";
import {loginAction} from "../../../../store/actions/auth";
import client from "../../../../api/client";

const Otp = () => {
    const dispatch = useDispatch<any>()
    const {isAuthenticating} = useSelector(({auth}) => auth)
    const {enqueueSnackbar} = useSnackbar()
    const [phone, setPhone] = useState<string>("")
    const [otp, setOtp] = useState<string>("")
    const [isAwaitingOtp, setIsAwaitingOtp] = useState(false)
    const [isRequestingOtp, setIsRequestingOtp] = useState<boolean>(false)

    const onOtpChange = (newOtp: string) => {
        setOtp(newOtp)
        if (newOtp.length === 6) {
            onSubmitOtp(newOtp)
        }
    }

    const onSubmitOtp = (otp: string) => {
        if (isRequestingOtp) {
            return
        }

        dispatch(loginAction(enqueueSnackbar, phone, otp))
    }

    const onRequestOtp = (e: ChangeEvent<HTMLFormElement>) => {
        if (isRequestingOtp) {
            return
        }

        e.preventDefault()
        setIsRequestingOtp(true)
        client.post("/otp", {phone}).then((res) => {
            enqueueSnackbar(res.data.message, {variant: "success"})
            setIsAwaitingOtp(true)
        })
            .catch((e) => handleError(e, enqueueSnackbar))
            .finally(() => setIsRequestingOtp(false));
    }

    if (isAwaitingOtp) {
        const onSubmitOtpForm = (e: ChangeEvent<HTMLFormElement>) => {
            e.preventDefault()
            onSubmitOtp(otp)
        }

        return <form onSubmit={onSubmitOtpForm} className="form">
            <div className="row">
                <div
                    style={{display: "flex", flex: 1, flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <div style={{marginBottom: "24px"}}>Please enter your OTP below</div>
                    <OtpInput
                        value={otp}
                        onChange={onOtpChange}
                        numInputs={6}
                        renderInput={(props) => <input {...props} className="form-field" disabled={isAuthenticating}/>}
                    />
                </div>
                <div className="submit-area">
                    <div className="form-submit">
                        <button type="submit" className="theme-btn-s3" disabled={isAuthenticating}>Submit</button>
                    </div>
                </div>
            </div>
        </form>
    }

    return <form onSubmit={onRequestOtp} className="form">
        <div className="row">
            <div style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                <TextField className="form-field"
                           type="string"
                           label="Phone number"
                           style={{width: "auto"}}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                           }}
                           InputLabelProps={{shrink: true}}
                           onChange={(e) => setPhone(e.target.value)}
                           disabled={isRequestingOtp}
                           value={phone}/>
            </div>
            <div className="submit-area">
                <div className="form-submit">
                    <button type="submit" className="theme-btn-s3" disabled={isRequestingOtp}>Request OTP</button>
                </div>
            </div>
        </div>
    </form>
}

export default Otp
