import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage'
import ErrorPage from '../ErrorPage'
import LoginPage from '../LoginPage'

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage/>} />
          <Route path='home' element={<Homepage/>} />
          <Route path='404' element={<ErrorPage/>} />
          <Route path='login' element={<LoginPage/>} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
