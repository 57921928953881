import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Doneness from '../../../store/constants/doneness.constants';

type Props = {
    onChange: (value: Doneness) => void
    value: Doneness
    disabled?: boolean
}

const DonenessSelector = (props: Props) => {
    return <Select onChange={(e) => props.onChange(e.target.value as Doneness)}
                   value={props.value}
                   disabled={props.disabled}>
        <MenuItem value="rare">Rare</MenuItem>
        <MenuItem value="medium rare">Medium Rare</MenuItem>
        <MenuItem value="medium">Medium</MenuItem>
        <MenuItem value="medium well">Medium Well-done</MenuItem>
        <MenuItem value="well done">Well-done</MenuItem>
    </Select>;
}

export default DonenessSelector;
