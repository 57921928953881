import handleError from "../../api/handleError";
import {EnqueueSnackbar} from "notistack";
import {ActionCreator, Dispatch} from "redux";
import client from "../../api/client";

interface Person {
    name: string
    dietaryRestrictions: string
    menu: string
    beefDoneness: string
}

export interface Attendee extends Person {
    id: string
    isGuestComing: boolean
    guest: Person
}

export const convertApiResponse = (attendee: any) => ({
    id: attendee.id,
    name: attendee.name,
    dietaryRestrictions: attendee.dietary_restrictions,
    menu: attendee.menu,
    beefDoneness: attendee.beef_doneness,
    isGuestComing: attendee.is_guest_coming,
    guest: {
        name: attendee.guest.name,
        dietaryRestrictions: attendee.guest.dietary_restrictions,
        menu: attendee.guest.menu,
        beefDoneness: attendee.guest.beef_doneness,
    },
})

export type Tokens = { accessToken: string | null, expiry: number | null }

export enum TokenEnum {
    ACCESS_TOKEN = "access_token",
    EXPIRY = "expiry",
}

export enum AuthActionType {
    SET_INITIALISED = "SET_INITIALISED",
    SET_IS_AUTHENTICATING = "SET_IS_AUTHENTICATING",
    SET_AUTHENTICATED = "SET_AUTHENTICATED",
    SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED"
}

export const setInitialisedAction = {type: AuthActionType.SET_INITIALISED}
export const setIsAuthenticatingAction = (payload: boolean) => ({type: AuthActionType.SET_IS_AUTHENTICATING, payload})
export const isAuthenticatedAction = (attendee: Attendee, tokens: Tokens) => ({
    type: AuthActionType.SET_AUTHENTICATED,
    payload: {attendee, tokens},
})

export const initialiseApp = (enqueueSnackbar: EnqueueSnackbar): ActionCreator<any> => async (dispatch: Dispatch<any>) => {
    const token = localStorage.getItem(TokenEnum.ACCESS_TOKEN)
    const expiry = localStorage.getItem(TokenEnum.EXPIRY)
    if (token === null || expiry === null || Date.now() >= +expiry ) {
        handleError("Your token has expired, please login again", enqueueSnackbar)
        dispatch(logoutAction())
        return
    }

    try {
        const res = await client.get("/user", {
            headers: {Authorization: `Bearer ${token}`}
        })

        dispatch(
            isAuthenticatedAction(
                convertApiResponse(res.data.data),
                {accessToken: token, expiry: +expiry}
            )
        )
    } catch (e: any) {
        handleError(e, enqueueSnackbar)
        dispatch(logoutAction())
    } finally {
        dispatch(setInitialisedAction)
    }
}

export const loginAction = (enqueueSnackbar: EnqueueSnackbar, phone: string, otp: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(setIsAuthenticatingAction(true))
        const res = await client.post("/login", {phone, otp});
        enqueueSnackbar(res.data.message, {variant: "success"})

        const {data: {token, attendee}}: { data: any } = res.data
        const {access_token: accessToken, expires_in: expiresIn} = token
        const expiry = Date.now() + expiresIn

        localStorage.setItem(TokenEnum.ACCESS_TOKEN, accessToken)
        localStorage.setItem(TokenEnum.EXPIRY, expiry.toString())

        dispatch(
            isAuthenticatedAction(
                convertApiResponse(attendee),
                {accessToken, expiry}
            )
        )
    } catch (e: any) {
        handleError(e, enqueueSnackbar);
    } finally {
        dispatch(setIsAuthenticatingAction(false))
    }
}

export const logoutAction = () => async (dispatch: Dispatch) => {
    localStorage.removeItem(TokenEnum.ACCESS_TOKEN)
    localStorage.removeItem(TokenEnum.EXPIRY)
    dispatch({type: AuthActionType.SET_UNAUTHENTICATED})
}
