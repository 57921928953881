import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import MenuConstants from '../../../store/constants/menu.constants';

type Props = {
    onChange: (value: string) => void
    value: string
    disabled?: boolean
}

const MenuSelector = (props: Props) => {
    return <Select onChange={(e) => props.onChange(e.target.value as MenuConstants)}
                   value={props.value}
                   disabled={props.disabled}>
        <MenuItem value="beef">Beef Tenderloin</MenuItem>
        <MenuItem value="fish">Cod w/ Lemon Hollandaise</MenuItem>
        <MenuItem value="chicken">Roasted Chicken Leg</MenuItem>
        <MenuItem value="vegetarian">Vegetarian</MenuItem>
    </Select>;
}

export default MenuSelector;
