import React from 'react'
import {useSelector} from "react-redux";
import { CircularProgress } from '@material-ui/core';
import vec1 from '../../images/contact/1.png';
import vec2 from '../../images/contact/2.png';
import SectionTitle from '../SectionTitle';
import Options from './components/Options';
import Otp from './components/Otp/Otp';

const RSVP = () => {
    const { isInitialising, isAuthenticated } = useSelector(({ auth }) => auth)
    if (isInitialising) {
        return <section className="wpo-contact-section" id="RSVP">
            <div className="container">
                <div className="wpo-contact-section-wrapper">
                    <div className="wpo-contact-form-area">
                        <SectionTitle MainTitle="RSVP"/>
                        <div
                            style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress/>
                        </div>
                        <div className="border-style"></div>
                    </div>
                    <div className="vector-1">
                        <img src={vec1} alt=""/>
                    </div>
                    <div className="vector-2">
                        <img src={vec2} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    }

    let element
    if (isAuthenticated) {
        element = <Options />
    } else {
        element = <Otp />
    }

    return <section className="wpo-contact-section" id="RSVP">
        <div className="container">
            <div className="wpo-contact-section-wrapper">
                <div className="wpo-contact-form-area">
                    <SectionTitle MainTitle="RSVP"/>
                    {element}
                    <div className="border-style"></div>
                </div>
                <div className="vector-1">
                    <img src={vec1} alt=""/>
                </div>
                <div className="vector-2">
                    <img src={vec2} alt=""/>
                </div>
            </div>
        </div>
    </section>
};

export default RSVP;
