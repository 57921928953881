import React, { Fragment } from 'react'
import SectionTitle from '../SectionTitle'

interface DishProps {
    title: string
    description: React.ReactNode
}

const Dish = (props: DishProps) => <div className="menu-section-wrapper-content-item-dish">
    <div className="dish-name">{props.title}</div>
    <div className="dish-description">{props.description}</div>
</div>

const Menu = () => {
    return <section className="wpo-menu-section" id="menu">
        <div className="container">
            <SectionTitle MainTitle="Menu"/>
            <div className="wpo-menu-section-wrapper">
                <div className="wpo-menu-section-wrapper-content">
                    <h5>5 courses</h5>
                    <p>free flow drinks</p>
                    <hr/>
                    <Dish title="amuse-bouche"
                          description="ponzu scallop, chilli soft-shell crab, thyme mushroom quiche"/>
                    <Dish title="appetizer" description="tiger prawns with seafood tomato chowder"/>
                    <Dish title="main"
                          description={
                              <Fragment>
                                  <p>/ beef tenderloin, roasted root vegetables with red wine jus</p>
                                  <p>/ cod, asparagus with lemon hollandaise</p>
                                  <p>/ roasted chicken leg, mash potatoes, root vegetables with onion gravy</p>
                                  <p>/ vegetarian option</p>
                              </Fragment>
                          }/>
                    <Dish title="palate cleanser" description="lychee sorbet"/>
                    <Dish title="dessert"
                          description="lemongrass panna cotta, dragon fruit, kiwi, coconut flakes"/>
                </div>
            </div>
        </div>
    </section>
}

export default Menu
