import {Switch} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import {useSnackbar} from 'notistack';
import React, {ChangeEvent, useEffect, useState} from 'react'
import handleError from '../../../api/handleError';
import DonenessConstants from '../../../store/constants/doneness.constants';
import MenuConstants from '../../../store/constants/menu.constants';
import DonenessSelector from './DonenessSelector';
import MenuSelector from './MenuSelector';
import {useDispatch, useSelector} from "react-redux";
import client from "../../../api/client";
import {convertApiResponse, logoutAction} from "../../../store/actions/auth";

const defaultMenu = (menu: string = ""): string => menu === "" ? "fish" : menu

const Options = () => {
    const dispatch = useDispatch<any>()
    const {attendee, tokens} = useSelector(({auth}) => auth)
    const {enqueueSnackbar} = useSnackbar()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [name, setName] = useState("")
    const [dietaryRestrictions, setDietaryRestrictions] = useState("")
    const [menu, setMenu] = useState<string>(defaultMenu())
    const [beefDoneness, setBeefDoneness] = useState<DonenessConstants>("medium")
    const [guest, setGuest] = useState<boolean>(false)
    const [guestName, setGuestName] = useState("")
    const [guestDietaryRestrictions, setGuestDietaryRestrictions] = useState("")
    const [guestMenu, setGuestMenu] = useState<string>(defaultMenu())
    const [guestBeefDoneness, setGuestBeefDoneness] = useState<DonenessConstants>("medium")

    const setAttendee = (attendee: any) => {
        setName(attendee.name)
        setDietaryRestrictions(attendee.dietaryRestrictions)
        setMenu(defaultMenu(attendee.menu))
        setBeefDoneness(attendee.beefDoneness)
        setGuest(attendee.isGuestComing)
        setGuestName(attendee.guest.name)
        setGuestDietaryRestrictions(attendee.guest.dietaryRestrictions)
        setGuestMenu(defaultMenu(attendee.guest.menu) as MenuConstants)
        setGuestBeefDoneness(attendee.guest.beefDoneness)
    }

    useEffect(() => {
        if (attendee) {
            setAttendee(attendee)
        }
    }, [attendee])

    const onChangeText = (callback: Function) => (e: ChangeEvent<HTMLInputElement>) => callback(e.target.value)
    const onChangeGuest = (e: ChangeEvent<HTMLInputElement>) => setGuest(e.target.checked)
    const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isLoading) {
            return
        }

        setIsLoading(true)
        client.put(`/attendees/${attendee.id}`, {
            name,
            dietary_restrictions: dietaryRestrictions,
            menu,
            beef_doneness: beefDoneness,
            is_guest_coming: guest,
            guest: {
                name: guestName,
                dietary_restrictions: guestDietaryRestrictions,
                menu: guestMenu,
                beef_doneness: guestBeefDoneness,
            },
        }, {
            headers: {Authorization: `Bearer ${tokens.accessToken}`}
        }).then((res) => {
            setAttendee(convertApiResponse(res.data.data))
            enqueueSnackbar(res.data.message, { variant: "success" })
        })
            .catch((e) => {
                handleError(e, enqueueSnackbar)
                dispatch(logoutAction())
            })
            .finally(() => setIsLoading(false))
    };

    return <form onSubmit={onSubmit} className="form">
        <div className="row">
            <div>
                <div className="form-field">
                    <TextField className="form-field"
                               onChange={onChangeText(setName)}
                               type="text"
                               name="name"
                               value={name}
                               label="Name"/>
                </div>
            </div>
            <div>
                <div className="form-field">
                    <TextField type="text"
                               label="Any dietary restrictions?"
                               className="form-field"
                               placeholder="N/A"
                               onChange={onChangeText(setDietaryRestrictions)}
                               InputLabelProps={{shrink: true}}
                               value={dietaryRestrictions}/>
                </div>
            </div>
            <div className="form-field">
                <div>What is your choice of main?</div>
                <MenuSelector onChange={setMenu} value={menu}/>
            </div>
            {menu === 'beef' &&
                <div className="form-field">
                    <div>How would you like it done?</div>
                    <DonenessSelector onChange={setBeefDoneness} value={beefDoneness}/>
                </div>
            }
            <div className="form-field">
                <span style={{marginRight: "16px"}}>Will you bring a guest?</span>
                <Switch onChange={onChangeGuest} color="primary" checked={guest}/>
            </div>
            <Collapse in={guest}>
                <div>
                    <div className="form-field">
                        <TextField className="form-field"
                                   type="text"
                                   label="What is the name of your guest?"
                                   value={guestName}
                                   onChange={onChangeText(setGuestName)}
                                   disabled={!guest}/>
                    </div>
                </div>
                <div>
                    <div className="form-field">
                        <TextField className="form-field"
                                   type="text"
                                   label="Does your guest have any dietary restrictions?"
                                   placeholder="N/A"
                                   InputLabelProps={{shrink: true}}
                                   onChange={onChangeText(setGuestDietaryRestrictions)}
                                   value={guestDietaryRestrictions}
                                   disabled={!guest}/>
                    </div>
                </div>
                <div className="form-field">
                    <div>What is your guest's choice of main?</div>
                    <MenuSelector onChange={setGuestMenu}
                                  value={guestMenu}
                                  disabled={!guest}/>
                </div>
                {guestMenu === 'beef' &&
                    <div style={{marginBottom: "16px"}}>
                        <div className="form-field">
                            <div>How would you like it done?</div>
                            <DonenessSelector onChange={setGuestBeefDoneness}
                                              value={guestBeefDoneness}
                                              disabled={!guest}/>
                        </div>
                    </div>
                }
            </Collapse>
            <div style={{marginTop: "24px"}}>
                <span>You can still change this until 15 Dec, 11:59 PM</span>
            </div>
            <div className="submit-area">
                <div className="form-submit">
                    <button type="submit" className="theme-btn-s3">Save</button>
                </div>
            </div>
        </div>
    </form>
}

export default Options
