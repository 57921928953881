import React, { Fragment } from 'react'
import Hero from "../../components/hero"
import Menu from "../../components/Menu/Menu"
import RSVP from '../../components/RSVP/RSVP'
import Scrollbar from '../../components/scrollbar'

const HomePage = () => <Fragment>
  <Hero/>
  <Menu/>
  <RSVP/>
  <Scrollbar/>
</Fragment>

export default HomePage
