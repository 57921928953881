import {SnackbarProvider, useSnackbar} from "notistack";
import React, {useEffect} from 'react';
import AllRoute from '../router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../sass/style.scss';
import {initialiseApp} from "../../store/actions/auth";
import {useDispatch} from "react-redux";


const App = () => {
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(initialiseApp(enqueueSnackbar));
  }, [])

  return <SnackbarProvider maxSnack={3}
                           autoHideDuration={5000}
                           anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
    <div className="App" id="scrool">
      <AllRoute/>
      <ToastContainer/>
    </div>
  </SnackbarProvider>
}

export default App;
