import React, { Fragment } from 'react';
import { Dialog, Grid, } from '@material-ui/core';

const LocationMap = ({maxWidth}) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    return (
        <Fragment>
            <a style={{color: "#30499d", textDecoration: "underline", fontSize: "1.1rem", cursor: "pointer"}}
               onClick={handleClickOpen}>
                Food For Thought, 93 Stamford Rd, #01-04/05, Singapore 178897
            </a>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
            >
                <Grid className="modalBody modal-body">
                    <Grid className="modalBody modal-body">
                        <iframe title="Location map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.187979380245!2d103.8485761!3d1.29644!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19a311ff963b%3A0x374b4071e355a618!2sFood%20For%20Thought!5e0!3m2!1sen!2ssg!4v1699538348436!5m2!1sen!2ssg"></iframe>
                    </Grid>
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default LocationMap


